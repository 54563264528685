import React, {Component} from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

class OptionsToggle extends Component {
  handleClick = (e) => {
    e.preventDefault();
    this.props.onClick(e);
  };

  render() {
    const {children, faIcon, label} = this.props;
    const faIconClasses = classnames('icon', faIcon);

    return (
      <div onClick={this.handleClick} className="input-icon input-icon-flip">
        <input
          id="transportable-input"
          aria-label={label}
          className="form-control form-control-lg rounded-0"
          value={children}
          readOnly
        />
        <span className={faIconClasses} aria-hidden="true"></span>
      </div>
    );
  }
}

OptionsToggle.propTypes = {
  onClick: PropTypes.func,
  faIcon: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.node
  ]).isRequired
};

export default OptionsToggle;
