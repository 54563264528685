import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {observer} from 'mobx-react';
import Dropdown from 'react-bootstrap/lib/Dropdown';
import OptionsToggle from './OptionsToggle';
import Counter from 'components/forms/Counter';
import AirlinesSelection from './AirlinesSelection';
import Modal from 'react-bootstrap/lib/Modal';

const paxTranslation = function (number, translations) {
  const {onePassenger, manyPassengers} = translations;
  return number === 1 ? onePassenger : manyPassengers.replace('{number}', number);
};

class Options extends Component {
  constructor() {
    super();
    this.state = {
      isOpen: false
    };
  }

  handleOpen = () => {
    this.setState({isOpen: !this.state.isOpen});
  };

  dataOptions() {
    const {
      directFlightsOnly,
      adults,
      minAdults,
      maxAdults,
      onChangeAdults,
      infants,
      minInfants,
      maxInfants,
      onChangeInfants,
      children,
      minChildren,
      maxChildren,
      onChangeChildren,
      selectSeatClass,
      seatClasses,
      seatClass,
      selectAirline,
      airlinesGroup,
      airline,
      toggleDirectFlightsOnly,
      translations
    } = this.props;
    return (
      <div className="list-group list-group-flush">
        <div className="list-group-item d-flex align-items-center justify-content-between px-3">
          <label className="leading-normal">
            <b>{translations.passengersAdult}</b><br/>
            <span className="text-muted">(+12)</span>
          </label>
          <Counter value={adults} min={minAdults} max={maxAdults} onChange={onChangeAdults} />
        </div>
        <div className="list-group-item d-flex align-items-center justify-content-between px-3">
          <label className="leading-normal">
            <b>{translations.passengersChild}</b><br/>
            <span className="text-muted">(2 - 12)</span>
          </label>
          <Counter value={children} min={minChildren} max={maxChildren} onChange={onChangeChildren} />
        </div>
        <div className="list-group-item d-flex align-items-center justify-content-between px-3">
          <label className="leading-normal">
            <b>{translations.passengersInfant}</b><br/>
            <span className="text-muted">(&lt;2)</span>
          </label>
          <Counter value={infants} min={minInfants} max={maxInfants} onChange={onChangeInfants} />
        </div>
        <div className="list-group-item px-3">
          <AirlinesSelection
            airlinesGroup={airlinesGroup}
            selectAirline={selectAirline}
            value={airline}
            translations={translations}
          />
          <div className="floating-label floating-label-show">
            <label>{translations.class}</label>
            <select className="form-control" onChange={(e) => selectSeatClass(e.target.value)} value={seatClass}>
              {seatClasses.map(({text, val}) => <option key={val} value={val}>{text}</option>)}
            </select>
          </div>
          <div className="checkbox mb-0">
            <label>
              <input
                checked={directFlightsOnly}
                type="checkbox"
                onChange={toggleDirectFlightsOnly}
              /> {translations.directFlightsOnly}
            </label>
          </div>
        </div>
      </div>
    );
  }

  render() {
    const {
      totalNumberOfPassengers,
      seatClassTitle,
      isMobile,
      translations
    } = this.props;

    const {isOpen} = this.state;

    return (
      <div className="grid-item-sm mb-2">
        {isMobile ?
          <div className="form-control form-control-lg rounded-0 input-icon input-icon-flip">
            <div onClick={this.handleOpen}>
              {paxTranslation(totalNumberOfPassengers, translations)}
              {seatClassTitle ? `, ${seatClassTitle}` : ''}
            </div>
            <span onClick={this.handleOpen} className="icon icon-triangle-sm-down" aria-hidden="true"></span>
            <Modal
              show={isOpen}
              onHide={this.handleOpen}
            >
              <div className="modal-header border-bottom-0">
                <h4 className="modal-title">{translations.passengers}</h4>
              </div>
              {this.dataOptions()}
              <Modal.Body>
                <button
                  onClick={this.handleOpen}
                  className="btn btn-secondary btn-block"
                >
                  {translations.apply}
                </button>
              </Modal.Body>
            </Modal>
          </div>
          :
          <Dropdown
            id="passengerSelection"
            onToggle={this.handleOpen}
            open={isOpen}
            componentClass="div"
          >
            <OptionsToggle
              bsRole="toggle"
              faIcon="icon-triangle-sm-down"
              label="passenger and vehicle options"
            >
              {paxTranslation(totalNumberOfPassengers, translations)}
              {seatClassTitle ? ` ${seatClassTitle}` : ''}
            </OptionsToggle>
            <Dropdown.Menu className="w-1-1">
              <li>
                {this.dataOptions()}
              </li>
            </Dropdown.Menu>
          </Dropdown>
        }
      </div>
    );
  }
}

Options.propTypes = {
  adults: PropTypes.number.isRequired,
  minAdults: PropTypes.number.isRequired,
  maxAdults: PropTypes.number.isRequired,
  onChangeAdults: PropTypes.func.isRequired,
  children: PropTypes.number.isRequired,
  minChildren: PropTypes.number.isRequired,
  maxChildren: PropTypes.number.isRequired,
  onChangeChildren: PropTypes.func.isRequired,
  infants: PropTypes.number.isRequired,
  minInfants: PropTypes.number.isRequired,
  maxInfants: PropTypes.number.isRequired,
  onChangeInfants: PropTypes.func.isRequired,
  airline: PropTypes.string.isRequired,
  selectAirline: PropTypes.func.isRequired,
  selectSeatClass: PropTypes.func.isRequired,
  seatClass: PropTypes.string.isRequired,
  seatClassTitle: PropTypes.string.isRequired,
  directFlightsOnly: PropTypes.bool.isRequired,
  toggleDirectFlightsOnly: PropTypes.func.isRequired,
  airlinesGroup: PropTypes.array.isRequired,
  seatClasses: PropTypes.object.isRequired,
  totalNumberOfPassengers: PropTypes.number.isRequired,
  isMobile: PropTypes.bool.isRequired,
  translations: PropTypes.shape({
    passengersAdult: PropTypes.string.isRequired,
    passengersChild: PropTypes.string.isRequired,
    passengersInfant: PropTypes.string.isRequired,
    class: PropTypes.string.isRequired,
    directFlightsOnly: PropTypes.string.isRequired,
    passengers: PropTypes.string.isRequired,
    apply: PropTypes.string.isRequired
  }).isRequired
};


export default observer(Options);
