import {computed, action, extendObservable, decorate} from 'mobx';
import * as R from 'ramda/src/index';
import moment from 'moment';
import {getAccommodation, setAccommodation} from 'providers/storageAccommodation';
import {getThreeLetterCode} from 'helpers/commonHelper';
import {cacheKey, cacheKeyAccommodation} from 'components/meta-results-tabs/components/results/common';
import metropolitan from 'helpers/widgets/booking/metropolitan-locations.json';
import {market} from 'helpers/stableValues';
import {mobileMediaQuery} from 'helpers/mediaQuery';

const getAirlinesGroup = (translations, alliances, airlines) => ([
  {
    label: translations.openGroupAlliance,
    id: 'alliances',
    options: alliances.map(({code, name}) => ({value: code, label: name}))
  },
  {
    label: translations.airlineCode,
    id: 'airlines',
    options: airlines.map(({code, name}) => ({value: code, label: name}))
  }
]);

const getAccomodationUrl = ({arr, obDate, ibDate, isRoundtrip,
  passengersAdult, passengersChild, passengersInfant}) => {
  const arrCode = getThreeLetterCode(arr),
    metropolitanCode = metropolitan[arrCode],
    aid = {gr: 1238596, uk: 813322, tp: 813325};
  return `https://www.booking.com/searchresults.html?aid=${aid[market]}&checkin=${moment(obDate).format('YYYY-MM-DD')}
    &checkout=${moment(isRoundtrip ? ibDate : moment(obDate).add(3, 'days')).format('YYYY-MM-DD')}
    &${metropolitanCode ? `dest_type=city&city=${metropolitanCode}` : `iata=${arrCode}`}&radius=10&sb_price_type=total
    &group_adults=${passengersAdult}&group_children=${passengersChild + passengersInfant}`;
};

class SearchMaskStore {
  MAX_SEAT_OCCUPANCY = 9;

  constructor({
    brand,
    airportsProvider,
    alliances,
    airlines,
    getRecentQueries,
    seatClasses,
    onSubmitForm,
    validateForm,
    fetchResultsUrl,
    showRecentQueries,
    translations
  }) {
    const seatClassesTranslated = this._translateSeatClass(seatClasses, translations);
    extendObservable(this, {
      isMobile: mobileMediaQuery.matches,
      product: 'flight',
      departure: '',
      departureSuggestionsIsLoading: false,
      departureSuggestions: [],
      arrivalSuggestions: [],
      arrivalSuggestionsIsLoading: false,
      arrival: '',
      roundTrip: true,
      obDate: moment().add(2, 'days').toDate(),
      ibDate: moment().add(5, 'days').toDate(),
      adults: 1,
      minAdults: 1,
      children: 0,
      minChildren: 0,
      infants: 0,
      minInfants: 0,
      airline: '',
      errors: {},
      seatClass: '',
      seatClassTitle: seatClassesTranslated[0].text,
      directFlightsOnly: false,
      extendedDates: false,
      focusedInput: null,
      triedToSubmit: false,
      seatClasses: seatClassesTranslated,
      brand,
      searchQueries: [],
      loading: false,
      showRecentQueries,
      searchAccommodation: {isChecked: true}
    });

    mobileMediaQuery.addEventListener('change', ({matches}) => this.isMobile = matches);

    getRecentQueries(cacheKey).then((searchQueries) => {
      this.searchQueries = searchQueries;
    });
    getAccommodation(cacheKeyAccommodation).then((value) => {
      this.searchAccommodation = {isChecked: value};
    });
    this.airlinesGroup = getAirlinesGroup(translations, alliances, airlines);
    this.airportsProvider = airportsProvider;
    this.translations = translations;
    this.depSuggestionsProvider = airportsProvider.createAirportsProvider('departure');
    this.arrSuggestionsProvider = airportsProvider.createAirportsProvider('arrival');
    this.onSubmit = onSubmitForm;
    this.validateForm = validateForm;
    this.fetchResultsUrl = fetchResultsUrl;
  }

  get maxAdults() {
    return this.MAX_SEAT_OCCUPANCY - this.children;
  }

  onChangeAdults = (value) => {
    this.adults = value;
    this.infants = Math.min(this.infants, this.adults);
  };

  get maxChildren() {
    return this.MAX_SEAT_OCCUPANCY - this.adults;
  }

  onChangeChildren = (value) => {
    this.children = value;
  };

  get maxInfants() {
    return this.adults;
  }

  onChangeInfants = (value) => {
    this.infants = value;
  };

  get totalNumberOfPassengers() {
    const {adults, children, infants} = this;
    return adults + children + infants;
  }

  onChangeSwitch = (field) => {
    field.isChecked = !field.isChecked;
  };

  setErrors = (errors) => {
    if (this.triedToSubmit && errors.errors) {
      this.loading = false;
    }
    this.errors = errors.errors || {};
  };

  setAirport = (type, val) => {
    this[type] = val;
    this.validateForm(this.buildQuery())
      .then(this.setErrors)
      .catch(this.setErrors);
  };

  setDate = (type, date) => {
    this[type] = date;
  };

  setDates = (date) => {
    const {fromDate, toDate} = date;
    this.obDate = fromDate;
    this.ibDate = toDate;
  };

  setRoundTrip = (val) => {
    this.roundTrip = val;

    if (this.roundTrip) {
      this.focusedInput = 'startDate';
    } else {
      this.focusedInput = true;
    }
  };

  selectAirline = (airlineCode) => {
    this.airline = airlineCode;
  };

  selectSeatClass = (seatCode) => {
    this.seatClass = seatCode;
    this.seatClassTitle = R.find(R.propEq('val', seatCode), this.seatClasses).text;
  };

  toggleDirectFlightsOnly = () => {
    this.directFlightsOnly = !this.directFlightsOnly;
  };

  swapAirports = () => {
    const {departure, arrival} = this;
    this.departure = arrival;
    this.arrival = departure;
  };

  setAirportWithCode = (type, airportCode) => {
    return this.airportsProvider.createAirportsProvider(type)(airportCode).then(({results}) => {
      const airport = R.find(R.propEq('airportCode', airportCode), results);
      if (airport) {
        this.setAirport(type, `${airport.tCity} (${airport.airportCode})`);
      }
    });
  };

  checkScreenWidth = ({matches}) => {
    this.isMobile = matches;
  };

  fillWithQuery = ({dep, arr, obDate, ibDate, isRoundtrip, passengersAdult, passengersChild, passengersInfant,
    seatClass, airlineCode, directFlightsOnly}) => {
    const departure = dep && getThreeLetterCode(dep);
    const arrival = arr && getThreeLetterCode(arr);
    if (departure && departure.length === 3) {
      this.setAirportWithCode('departure', departure);
    }

    if (arrival && arrival.length === 3) {
      this.setAirportWithCode('arrival', arrival);
    }

    if (this._ifObDateIsSameOrAfterToday(obDate)) {
      this.setDate('obDate', obDate);
    }

    if (this._ifObDateIsSameOrAfterTodayAndIbDateIsSameOrAfterObDate(obDate, ibDate)) {
      this.setDates({fromDate: obDate, toDate: ibDate});
    }

    if (isRoundtrip) {
      this.setRoundTrip(isRoundtrip === '1');
    }

    this.adults = parseInt(passengersAdult, 10) || 1;
    this.children = parseInt(passengersChild, 10) || 0;
    this.infants = parseInt(passengersInfant, 10) || 0;

    this.selectSeatClass(seatClass || '');

    this.selectAirline(airlineCode || '');

    if (directFlightsOnly) {
      this.directFlightsOnly = directFlightsOnly === '1';
    }
  };

  submitForm = (event) => {
    event.preventDefault();
    this.triedToSubmit = true;
    this.errors = [];
    const buildQuery = this.buildQuery();
    this.fetchResultsUrl(buildQuery)
      .then((url) => {
        if (url.split('#')[1] !== window.location.hash.substr(1)) {
          this.loading = true;
          const {isMobile, searchAccommodation: {isChecked}} = this;
          setAccommodation(cacheKeyAccommodation, isChecked);
          this.onSubmit(
            url,
            !isMobile && isChecked && getAccomodationUrl(buildQuery)
          );
        }
      })
      .catch(this.setErrors);
  };

  processSuggestions = ({name, results, term}) => {
    if (name === 'departure' && term === this.departure) {
      this.departureSuggestionsIsLoading = false;
      this.departureSuggestions = results;
    }
    if (name === 'arrival' && term === this.arrival) {
      this.arrivalSuggestionsIsLoading = false;
      this.arrivalSuggestions = results;
    }
  };

  fetchSuggestions = (type, q) => {
    if (q.value.length < 3) {
      return;
    }
    if (type === 'departure') {
      this.departureSuggestionsIsLoading = true;
      this.depSuggestionsProvider(q.value)
        .then(this.processSuggestions);
    }
    if (type === 'arrival') {
      this.arrivalSuggestionsIsLoading = true;
      this.arrSuggestionsProvider(q.value)
        .then(this.processSuggestions);
    }
  };

  clearSuggestions = (type) => {
    if (type === 'departure') {
      this.departureSuggestions = [];
    } else if (type === 'arrival') {
      this.arrivalSuggestions = [];
    }
  };

  buildQuery = () => {
    return {
      dep: this.departure,
      arr: this.arrival,
      obDate: this.obDate,
      ibDate: this.ibDate,
      isRoundtrip: this.roundTrip,
      passengersAdult: this.adults,
      passengersChild: this.children,
      passengersInfant: this.infants,
      seatClass: this.seatClass,
      airlineCode: this.airline,
      directFlightsOnly: this.directFlightsOnly,
      extendedDates: this.extendedDates
    };
  };

  startLoading = () => {
    this.loading = true;
  };

  stopLoading = () => {
    this.loading = false;
  };

  _translateSeatClass = (seatClasses, translations) => {
    return seatClasses.map((seatClass) => ({val: seatClass.val, text: translations[seatClass.text]}));
  };

  _ifObDateIsSameOrAfterToday = (obDate) => {
    const today = moment();
    return obDate && moment(obDate).isSameOrAfter(today, 'day');
  };

  _ifObDateIsSameOrAfterTodayAndIbDateIsSameOrAfterObDate = (obDate, ibDate) => {
    const today = moment();
    return obDate &&
      ibDate &&
      moment(obDate).isSameOrAfter(today, 'day') &&
      moment(ibDate).isSameOrAfter(obDate, 'day');
  };
}

decorate(SearchMaskStore, {
  maxAdults: computed,
  onChangeAdults: action,
  maxChildren: computed,
  onChangeChildren: action,
  maxInfants: computed,
  onChangeInfants: action,
  totalNumberOfPassengers: computed,
  onChangeSwitch: action,
  setErrors: action,
  setAirport: action,
  setDate: action,
  setDates: action,
  setRoundTrip: action,
  selectAirline: action,
  selectSeatClass: action,
  toggleDirectFlightsOnly: action,
  swapAirports: action,
  setAirportWithCode: action,
  fillWithQuery: action,
  submitForm: action,
  processSuggestions: action,
  fetchSuggestions: action,
  clearSuggestions: action,
  startLoading: action,
  stopLoading: action,
  checkScreenWidth: action
});

export default SearchMaskStore;
