import {extendObservable, action, decorate} from 'mobx';
import {fetchGDPRData} from 'providers/CookiePreferences';
import {gdprCookies, updateGDPRData, getGDPRTypeValue} from 'providers/CookiePreferences';
import {languageFromUrl, addDataLayer} from 'helpers/commonHelper';
import {isTravelplanet24} from 'helpers/stableValues';

const COOKIE_LINK = '/privacy-policy#cookies';

const eventAcceptCookie = (analytics, social, affiliate) => {
  if (analytics) {
    addDataLayer({event: 'loadAnalytics'});
  }
  if (social) {
    addDataLayer({event: 'loadFacebookPixel'});
  }
  if (affiliate) {
    addDataLayer({event: 'loadAffiliate'});
  }
};

class CookiePreferenceStore {
  constructor({translations}) {
    const {analyticsTitle, analyticsDescription, advertisingTitle, advertisingDescription,
      affiliateTitle, affiliateDescription, socialTitle, socialDescription} = translations;
    const analytics = getGDPRTypeValue(gdprCookies.ana, !isTravelplanet24);
    const social = getGDPRTypeValue(gdprCookies.soc);
    const affiliate = getGDPRTypeValue(gdprCookies.aff);
    const gdprData = [
      {
        id: gdprCookies.ana,
        name: analyticsTitle,
        description: analyticsDescription,
        value: analytics
      },
      {
        id: gdprCookies.soc,
        name: socialTitle,
        description: socialDescription,
        value: social
      },
      {
        id: gdprCookies.aff,
        name: affiliateTitle,
        description: affiliateDescription,
        value: affiliate
      },
      {
        id: gdprCookies.adv,
        name: advertisingTitle,
        description: advertisingDescription,
        value: getGDPRTypeValue(gdprCookies.adv, false)
      }
    ];
    // if (R.isEmpty(initialCookieData)) {
    //   updateGDPRData(gdprData);
    // }
    extendObservable(this, {
      gdprData,
      initCookieVal: Boolean(fetchGDPRData()),
      cookieLink:  `${languageFromUrl()}${COOKIE_LINK}`, 
      modalIsOpen: false
    });
    eventAcceptCookie(analytics, social, affiliate);
  }

  toggleModal = () => {
    this.modalIsOpen = !this.modalIsOpen;
  };

  toggleAll = (type) => {
    this.gdprData.forEach((item) => item.value = type);
    this.saveGDPR();
  };

  toggleOption = (item) => {
    item.value = !item.value;
  };

  saveGDPR = () => {
    updateGDPRData(this.gdprData);
    this.modalIsOpen = false;
    this.initCookieVal = true;
    addDataLayer({event: 'submitCookies'});
  };
}

decorate(CookiePreferenceStore, {
  toggleModal: action,
  toggleAll: action,
  toggleOption: action,
  saveGDPR: action
});

export default CookiePreferenceStore;
