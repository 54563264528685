import * as R from 'ramda/src/index';
import moment from 'moment';
import storage from 'utils/storage';

const cache = storage.createStore({name: 'partnerCode'});
const getPartnerCodeHistory = async (cacheKey) => {
  const res = await cache.getItem(cacheKey);
  return res || [];
};

const getLastItem = R.pipe(
  R.last,
  R.propOr('', 'partnerCode')
);

const oneMonthBefore = moment().subtract(1, 'month');

const setPartnerCodeHistory = async (cacheKey, value) => {
  const history = await getPartnerCodeHistory(cacheKey);
  const lastItem = getLastItem(history);
  const filterData = history.filter(({timestamp}) => !moment(timestamp * 1000).isBefore(oneMonthBefore));
  if (lastItem !== value) {
    cache.setItem(
      cacheKey,
      [...filterData, {partnerCode: value, timestamp: moment().unix()}]
    );
  }
};

export {getPartnerCodeHistory, setPartnerCodeHistory};
