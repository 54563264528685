import Cookies from 'js-cookie';

const GDPR_COOKIE_NAME = 'user_consent';
export const gdprCookies = {
  ana: 'ana',
  soc: 'soc',
  aff: 'aff',
  adv: 'adv'
};

export const fetchGDPRData = () => Cookies.getJSON(GDPR_COOKIE_NAME);

export const updateGDPRData = (cookies) => {
  const data = cookies.reduce((acc, item) => {
    acc[item.id] = item.value;
    return acc;
  }, {});
  Cookies.set(GDPR_COOKIE_NAME, data, {expires: 365});
};

const initialCookieData = fetchGDPRData() || {};

export const getGDPRTypeValue = (type, value = false) => {
  const cookieValue = initialCookieData[type];
  return typeof initialCookieData === 'boolean' ?
    initialCookieData : typeof cookieValue === 'boolean' ?
      cookieValue : value;
};
