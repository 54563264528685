import storage from 'utils/storage';

const cache = storage.createStore({name: 'accomodation'});
const getAccommodation = async (cacheKey) => {
  const res = await cache.getItem(cacheKey);
  return (typeof res === 'boolean') ? res : true;
};

const setAccommodation = (cacheKey, value) => {
  cache.setItem(cacheKey, value);
};

export {getAccommodation, setAccommodation};
