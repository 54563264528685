import React, {Fragment, memo, useState} from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import Slider from 'react-slick';
import Collapse from 'react-bootstrap/lib/Collapse';
import DateHelper from 'lib/helpers/date-helper';

const settings = {
  className: 'recentSearches-slider',
  dots: false,
  infinite: false,
  speed: 500,
  slidesToShow: 4,
  slidesToScroll: 4,
  arrows: true,
  responsive: [
    {
      breakpoint: 990,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2
      }
    },
    {
      breakpoint: 760,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1
      }
    }
  ]
};

const renderRecentSearches = ({dep, arr, isRoundtrip, obDate, ibDate}) => {
  const dates = moment(obDate).format('DD MMM') +
    (isRoundtrip === '1' ? ` - ${moment(ibDate).format('DD MMM')}` : '');

  return (
    <span>{`${dep} - ${arr}`}<br/>{dates}</span>
  );
};

renderRecentSearches.propTypes = {
  dep: PropTypes.string.isRequired,
  arr: PropTypes.string.isRequired,
  isRoundtrip: PropTypes.bool.isRequired,
  obDate: PropTypes.string.isRequired,
  ibDate: PropTypes.string.isRequired
};

const RecentSearches = ({searchQueries, showRecentQueries,
  fillWithQuery, translations: {recentSearches}}) => {
  const [showQueries, setShowQueries] = useState(showRecentQueries);
  const toggleSearches = () => setShowQueries((showQueries) => !showQueries);

  return (
    <Fragment>
      <a className="text-light opacity-mid" onClick={toggleSearches}>
        {recentSearches}&nbsp;
        <i className={`preventClick icon icon-sm ${showQueries ? 'icon-up-arrow' : 'icon-down-arrow'}`} />
      </a>
      <Collapse in={showQueries}>
        <div>
          <hr/>
          <Slider {...settings}>
            {searchQueries.map((query, i) =>
              <a key={i} className="px-3 text-left text-light" dir="auto"
                onClick={() => {
                  const {obDate, ibDate} = query;
                  const transformQuery = {
                    ...query,
                    obDate: DateHelper.parseDate(obDate),
                    ibDate: DateHelper.parseDate(ibDate)
                  };
                  fillWithQuery(transformQuery);
                }}
              >
                {renderRecentSearches(query)}
              </a>
            )}
          </Slider>
        </div>
      </Collapse>
    </Fragment>
  );
};

RecentSearches.propTypes = {
  searchQueries: PropTypes.object.isRequired,
  showRecentQueries: PropTypes.bool.isRequired,
  fillWithQuery: PropTypes.func.isRequired,
  translations: PropTypes.shape({
    recentSearches: PropTypes.string.isRequired
  })
};

export default memo(RecentSearches);
