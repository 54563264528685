import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import {observer} from 'mobx-react';
import BottomBar from 'components/CookieConsent/BottomBar';
import CookiePreferences from 'components/CookiePreferences';

const CookieContainer = ({cookiePreferenceStore,
  cookieConsentTranslations, gdprPreferencesTranslations}) =>
  <>
    <BottomBar
      {...cookiePreferenceStore}
      cookiePopupTitle={gdprPreferencesTranslations.cookiePopupTitle}
      translations={cookieConsentTranslations}
    />
    <CookiePreferences
      {...cookiePreferenceStore}
      translations={gdprPreferencesTranslations}
    />
  </>;

CookieContainer.propTypes = {
  cookiePreferenceStore: PropTypes.shape({
    toggleModal: PropTypes.func.isRequired,
    toggleAll: PropTypes.func.isRequired
  }).isRequired,
  cookieConsentTranslations: PropTypes.object.isRequired,
  gdprPreferencesTranslations: PropTypes.shape({
    cookiePopupTitle: PropTypes.string.isRequired
  }).isRequired
};

export default observer(CookieContainer);
