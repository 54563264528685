const matchText = (text, search) => text.replace(/\s/g,'').toLowerCase().includes(
  search.replace(/\s/g,'').toLowerCase()
);

const removeAccent = (str) => str.normalize('NFD').replace(/[\u0300-\u036f]/g, '');

const onlyCharacters = (text) => text.replace(/\d/g,'').replace(/[@!^&/\\#,+()$~%.'":*?<>{}_|=\-`]/g, '');

const getTransportable = (number, plural, singular) => {
  if (number === 1) {
    return singular.replace('{number}', number);
  }
  return plural.replace('{number}', number);
};

const joinWordsWithAmpLast = (items) => {
  const filteredItems = items.filter(Boolean);

  if (filteredItems.length === 1) {
    return filteredItems[0];
  } 
  const textItemsExceptLast = filteredItems.slice(0, -1).join(', ');
  const lastTrItem = filteredItems.slice(-1)[0];
  return `${textItemsExceptLast} & ${lastTrItem}`;
};

const replaceVowelsWithAd = (word) =>  word.replace(/\ba(?=\s*[aeiou])/ig, 'ad');

const initialName = (firstName, lastName) => `${firstName?.[0]?.toUpperCase()}${lastName?.[0]?.toUpperCase()}`;

const extractCountryCode = (label) => {
  const match = label.match(/\+\d+/);
  return match ? match[0] : '';
};

const extractCountryName = (label) => {
  const match = label.match(/^(.+?) \(\+\d+\)$/);
  return match ? match[1] : label;
};

export {
  matchText,
  onlyCharacters,
  removeAccent,
  getTransportable,
  joinWordsWithAmpLast,
  replaceVowelsWithAd,
  initialName,
  extractCountryCode,
  extractCountryName
};