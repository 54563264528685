import React from 'react';
import Modal from 'react-bootstrap/lib/Modal';
import PropTypes from 'prop-types';
import {PropTypes as MobxPropTypes} from 'mobx-react';
import {observer} from 'mobx-react';
import CookieOption from './components/CookieOption';

const CookiePreferences = ({toggleModal, toggleOption,
  toggleAll, gdprData, modalIsOpen, saveGDPR, translations}) => {
  const {submit, cookiePopupTitle, cookiePopupDescription,
    recommendedSettings, technicalTitle, technicalDescription} = translations;

  const technical = {id: 'technical', name: technicalTitle, description: technicalDescription};

  return (
    <Modal
      show={modalIsOpen}
      onHide={toggleModal}
    >
      <div className="modal-header">
        <h4 className="modal-title">{cookiePopupTitle}</h4>
        <button onClick={toggleModal} type="button" className="close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <Modal.Body>
        <p dangerouslySetInnerHTML={{__html: cookiePopupDescription}} />
        <button
          onClick={() => {toggleAll(true);}}
          id="rec-settings"
          className="btn btn-vivid-cerulean mb-2 btn-sm"
        >{recommendedSettings}</button>
        <div className="list-group list-group-flush">
          <CookieOption item={technical} translations={translations} />
          <div className="cookieOptionWrapper">
            {
              gdprData.map((cookie) =>
                <CookieOption
                  key={cookie.id}
                  item={cookie}
                  toggleOption={toggleOption}
                  translations={translations}
                />
              )
            }
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button
          className="btn btn-primary"
          onClick={saveGDPR}>{submit}</button>
      </Modal.Footer>
    </Modal>
  );
};

CookiePreferences.propTypes = {
  gdprData: MobxPropTypes.arrayOrObservableArrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      description: PropTypes.string.isRequired,
      value: PropTypes.bool.isRequired
    })
  ),
  modalIsOpen: PropTypes.bool.isRequired,
  toggleModal: PropTypes.func.isRequired,
  toggleOption: PropTypes.func.isRequired,
  toggleAll: PropTypes.func.isRequired,
  saveGDPR: PropTypes.func.isRequired,
  translations: PropTypes.shape({
    submit: PropTypes.string.isRequired,
    cookiePopupTitle: PropTypes.string.isRequired,
    cookiePopupDescription: PropTypes.string.isRequired,
    recommendedSettings: PropTypes.string.isRequired,
    analyticsTitle: PropTypes.string.isRequired,
    analyticsDescription: PropTypes.string.isRequired,
    technicalTitle: PropTypes.string.isRequired,
    technicalDescription: PropTypes.string.isRequired
  }).isRequired
};

export default observer(CookiePreferences);
