import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import {observer} from 'mobx-react';
import * as R from 'ramda/src/index';
import qs from 'qs';
import store from './store';
import DatePickerWrapper from './DatePickerWrapper';
import Options from './Options';
import Airports from './Airports';
import RecentSearches from './RecentSearches';
import SearchButton from './SearchButton';
import SwitchElement from 'ferries/helpers/SwitchElement';

const airportsPropNames = Object.keys(Airports.propTypes);

const SearchMask = ({store}) => {
  const {isMobile, searchQueries, fillWithQuery, searchAccommodation,
    showRecentQueries, onChangeSwitch, translations} = store;

  useEffect(() => {
    if (location.search) {
      const searchQueryFromUrl = location.search.slice(1);
      const query = qs.parse(searchQueryFromUrl);
      fillWithQuery(query);
    }
  }, [fillWithQuery]);

  const airportsProps = R.pick(airportsPropNames, store);
  const datePickersProps = R.pick([
    'isMobile',
    'obDate',
    'ibDate',
    'roundTrip',
    'setRoundTrip',
    'translations',
    'setDate',
    'setDates'], store);
  const optionsProps = R.pick([
    'isMobile',
    'adults',
    'minAdults',
    'maxAdults',
    'onChangeAdults',
    'children',
    'minChildren',
    'maxChildren',
    'onChangeChildren',
    'infants',
    'minInfants',
    'maxInfants',
    'onChangeInfants',
    'airline',
    'selectAirline',
    'selectSeatClass',
    'seatClass',
    'seatClassTitle',
    'directFlightsOnly',
    'toggleDirectFlightsOnly',
    'airlinesGroup',
    'seatClasses',
    'totalNumberOfPassengers',
    'translations'], store);
  const searchButtonProps = R.pick([
    'product',
    'submitForm',
    'loading',
    'translations'], store);

  return (
    <div className="searchMask-wrapper">
      <form className="searchMask">
        <div className="grid no-gutter">
          <div className="destinationInput-wrapper grid-item-xl-auto grid-item-lg-1-1">
            <Airports {...airportsProps} />
          </div>
          <DatePickerWrapper {...datePickersProps} />
          <Options {...optionsProps} />
          <SearchButton  {...searchButtonProps} />
        </div>
      </form>
      {
        !isMobile &&
          <div className="d-flex align-items-center mb-2">
            <SwitchElement
              name="accommodation_switch"
              field={searchAccommodation}
              onChangeField={onChangeSwitch}
            />
            <span className="text-white ml-3 pb-2">{translations.searchAccommodation}</span>
          </div>
      }
      {
        searchQueries.length > 0 &&
          <RecentSearches
            searchQueries={searchQueries}
            showRecentQueries={showRecentQueries}
            fillWithQuery={fillWithQuery}
            translations={translations}
          />
      }
    </div>
  );
};

SearchMask.propTypes = {
  store: PropTypes.shape({
    ...Airports.propTypes,
    isMobile: PropTypes.bool.isRequired,
    brand: PropTypes.string.isRequired,
    product: PropTypes.string.isRequired,
    submitForm: PropTypes.func.isRequired,
    searchQueries: PropTypes.object,
    showRecentQueries: PropTypes.bool.isRequired,
    fillWithQuery: PropTypes.func.isRequired,
    adults: PropTypes.number,
    children: PropTypes.number,
    infants: PropTypes.number,
    loading: PropTypes.bool.isRequired,
    searchAccommodation: PropTypes.shape({
      isChecked: PropTypes.bool.isRequired
    }),
    onChangeSwitch: PropTypes.func.isRequired,
    translations: PropTypes.shape({
      search: PropTypes.string.isRequired,
      searchAccommodation: PropTypes.string.isRequired,
      recentSearches: PropTypes.string.isRequired
    })
  })
};

export default observer(SearchMask);
export {store};
