import React from 'react';
import PropTypes from 'prop-types';
import {observer} from 'mobx-react';
import Button from 'helpers/Button';

const BottomBar = ({initCookieVal, cookieLink, toggleModal, toggleAll,
  cookiePopupTitle, translations}) => {
  const {acceptAll, learnMore, consentMessage, noCookies} = translations;

  if (initCookieVal) {
    return null;
  }

  return (
    <div className="cookie-consent-control">
      <div className="container">
        <div className="grid align-items-center p-1">
          <div className="grid-item-md text-justify">
            <div>
              {consentMessage}
              <a
                href={cookieLink}
                target="_blank"
                rel="noopener noreferrer"
                className="ml-2 text-accent"
                title={learnMore}
              >
                {learnMore}
              </a>
            </div>
            <div className="d-md-flex justify-content-md-end mb-md-2 my-3 my-md-0">
              <span
                className="mb-2 mb-md-0 d-block d-md-inline text-accent cursor-pointer"
                onClick={toggleModal}
              >
                {cookiePopupTitle}
              </span>
              <span
                className="ml-md-3 d-block d-md-inline text-accent cursor-pointer"
                onClick={() => toggleAll(false)}
              >
                {noCookies}
              </span>
            </div>
          </div>
          <div className="grid-item-md-auto text-center">
            <Button
              aria-label={acceptAll}
              status="primary"
              size="sm"
              onClick={() => toggleAll(true)}
            >
              {acceptAll}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

BottomBar.propTypes = {
  initCookieVal: PropTypes.bool.isRequired,
  cookieLink: PropTypes.string.isRequired,
  toggleModal: PropTypes.func.isRequired,
  toggleAll: PropTypes.func.isRequired,
  cookiePopupTitle: PropTypes.string.isRequired,
  translations: PropTypes.shape({
    acceptAll: PropTypes.string.isRequired,
    learnMore: PropTypes.string.isRequired,
    consentMessage: PropTypes.string.isRequired,
    noCookies: PropTypes.string.isRequired
  }).isRequired
};

export default observer(BottomBar);
