import React from 'react';
import PropTypes from 'prop-types';

const ToggleSwitch = ({option, checked, name, disabled, onChange}) => (
  <label className="cursor-pointer">
    <span className="toggle-switch">
      <input
        type="checkbox"
        checked={checked}
        value={option}
        name={name}
        disabled={disabled}
        onChange={onChange}
      />
      <span className="slider"/>
    </span>
  </label>
);

ToggleSwitch.defaultProps = {
  disabled: false
};

ToggleSwitch.propTypes = {
  option: PropTypes.string.isRequired,
  checked: PropTypes.bool.isRequired,
  name: PropTypes.string.isRequired,
  disabled: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired
};

export default ToggleSwitch;
