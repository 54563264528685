import React from 'react';
import PropTypes from 'prop-types';
import Autosuggest from 'react-autosuggest';
import {observer} from 'mobx-react';
import {compact} from 'lodash/fp';

function renderSuggestion(suggestion) {
  const {tCity, tAirportName, tCountryName, airportCode} = suggestion;
  const title = compact([tCity, tAirportName]).join(' - ');
  return (
    <div>
      <div className="float-right text-small"><b>{airportCode}</b></div>
      <div className="text-truncate">
        {title}
      </div>
      <div className="text-small text-muted">{tCountryName}</div>
    </div>
  );
}

function getSuggestionValue({tCity, airportCode, children}) {
  if (children && children.length) {
    return `${tCity} (${airportCode})`;
  }
  return `${tCity} (${airportCode})`;
}

function selectAll(event) {
  event.target.focus();
  event.target.setSelectionRange(0, event.target.value.length);
}

const theme = {
  container: 'dropdown',
  containerOpen: 'open',
  input: 'form-control form-control-lg rounded-0 pr-6',
  inputOpen: 'react-autosuggest__input--open',
  inputFocused: 'react-autosuggest__input--focused',
  suggestionsContainer: 'dropdown-menu w-1-1 py-0',
  suggestionsContainerOpen: 'react-autosuggest__suggestions-container--open',
  suggestionsList: 'list-group list-group-flush scrollable-menu',
  suggestion: 'list-group-item px-3',
  suggestionFirst: 'react-autosuggest__suggestion--first',
  suggestionHighlighted: 'bg-light',
  sectionContainer: 'react-autosuggest__section-container',
  sectionContainerFirst: 'react-autosuggest__section-container--first',
  sectionTitle: 'react-autosuggest__section-title'
};

function Airports(props) {
  const {
    departure,
    departureSuggestions,
    arrival,
    arrivalSuggestions,
    swapAirports,
    setAirport,
    fetchSuggestions,
    departureSuggestionsIsLoading,
    arrivalSuggestionsIsLoading,
    clearSuggestions,
    triedToSubmit,
    errors,
    translations: {dep},
    translations: {arr}
  } = props;

  return (
    <div className="grid no-gutter">
      <div className="grid-item-sm mb-2">
        <Autosuggest
          theme={theme}
          inputProps={{
            type: 'search',
            placeholder: departure.length ? '' : dep, // Temp fix for safari bug, placeholder overflowing
            value: departure,
            onClick: selectAll,
            onChange: (ignore, {newValue}) => setAirport('departure', newValue)
          }}
          suggestions={departureSuggestions.slice()}
          onSuggestionsFetchRequested={(value) => fetchSuggestions('departure', value)}
          onSuggestionsClearRequested={(value) => clearSuggestions('departure', value)}
          getSuggestionValue={getSuggestionValue}
          renderSuggestion={renderSuggestion}
          id="departure"
        />
        {
          departureSuggestionsIsLoading &&
          <div className="autosuggestLoading">
            <i className="icon icon-dots-anim-2 icon-is-spinning"/>
          </div>
        }
        {
          errors.dep &&
          triedToSubmit &&
          <div className='searchMask-error'>{errors.dep[0]}</div>
        }
        <button aria-label="swap airports" className="swapAirports" type="button" onClick={swapAirports}>
          <i className="icon icon-swap-horizontal" aria-hidden="true"/>
        </button>
      </div>
      <div className="grid-item-sm mb-2">
        <Autosuggest
          theme={theme}
          inputProps={{
            type: 'search',
            placeholder: arrival.length ? '' : arr, // Temp fix for safari bug, placeholder overflowing
            value: arrival,
            onClick: selectAll,
            onChange: (ignore, {newValue}) => setAirport('arrival', newValue)
          }}
          suggestions={arrivalSuggestions.slice()}
          onSuggestionsFetchRequested={(value) => fetchSuggestions('arrival', value)}
          onSuggestionsClearRequested={(value) => clearSuggestions('arrival', value)}
          getSuggestionValue={getSuggestionValue}
          renderSuggestion={renderSuggestion}
          id="arrival"
        />
        {
          arrivalSuggestionsIsLoading &&
          <div className="autosuggestLoading">
            <i className="icon icon-dots-anim-2 icon-is-spinning"/>
          </div>
        }
        {
          errors.arr &&
          triedToSubmit &&
          <div className='searchMask-error'>{errors.arr[0]}</div>
        }
      </div>
    </div>
  );
}

Airports.propTypes = {
  errors: PropTypes.object.isRequired,
  departure: PropTypes.string.isRequired,
  arrival: PropTypes.string.isRequired,
  departureSuggestions: PropTypes.object.isRequired,
  arrivalSuggestions: PropTypes.object.isRequired,
  swapAirports: PropTypes.func.isRequired,
  setAirport: PropTypes.func.isRequired,
  brand: PropTypes.string.isRequired,
  fetchSuggestions: PropTypes.func.isRequired,
  clearSuggestions: PropTypes.func.isRequired,
  departureSuggestionsIsLoading: PropTypes.bool.isRequired,
  arrivalSuggestionsIsLoading: PropTypes.bool.isRequired,
  triedToSubmit: PropTypes.bool.isRequired,
  translations: PropTypes.object.isRequired
};

export default observer(Airports);
