import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {observer} from 'mobx-react';
import {DayPicker} from 'react-day-picker';
import moment from 'moment';
import Dropdown from 'react-bootstrap/lib/Dropdown';
import Modal from 'react-bootstrap/lib/Modal';
import DropdownMenu from 'react-bootstrap/lib/DropdownMenu';
import OptionsToggle from './OptionsToggle';
import RoundtripRadios from './RoundtripRadios';
import useLocalizationLoadFile from 'helpers/hooks/useLocalizationLoadFile';
import {formatMonthDayPeriod, formatYearMonthDayPeriod} from 'lib/helpers/globalize-helper';

const DatePickerWrapper = ({
  isMobile, obDate, ibDate, roundTrip, setRoundTrip, setDate, setDates, tripIndex,
  cssClass, disableBefore, /*disableAfter,*/ translations
}) => {
  const [showDatePicker, setShowDatePicker] = useState(false);
  const [chosenObDate, setChosenObDate] = useState(false);
  const [dayPickerMonth, setDayPickerMonth] = useState(undefined);
  const dayPickerLocale = useLocalizationLoadFile();

  const handleDateClick = (obj, date) => {
    if (roundTrip) {
      if (moment(date).isBefore(moment(obDate)) || !chosenObDate) {
        setDates({fromDate: date, toDate: date}, tripIndex);
        setChosenObDate(true);
      } else {
        toggleDatePicker();
        setChosenObDate(false);
        setDate('ibDate', date, tripIndex);
      }
    } else {
      setDates({fromDate: date, toDate: date}, tripIndex);
      toggleDatePicker();
    }
  };

  const toggleDatePicker = () => setShowDatePicker((showDatePicker) => !showDatePicker);

  const dateOptions = () => {
    const selectedDays = roundTrip ? {from: obDate, to: ibDate} : obDate;

    return (
      <div className="list-group list-group-flush">
        <RoundtripRadios
          roundTrip={roundTrip}
          setRoundTrip={setRoundTrip}
          onCloseClick={toggleDatePicker}
          translations={translations}
        />
        <DayPicker
          mode="range"
          numberOfMonths={2}
          month={dayPickerMonth ?? obDate}
          selected={selectedDays}
          fromDate={disableBefore && disableBefore instanceof Date ? disableBefore : moment().toDate()}
          toDate={moment().add(1, 'years').toDate()}
          locale={dayPickerLocale}
          onSelect={handleDateClick}
          onMonthChange={setDayPickerMonth}
        />
      </div>
    );
  };

  const displayObDate = formatMonthDayPeriod(moment(obDate));
  const displayIbDate = formatMonthDayPeriod(moment(ibDate));
  const displayValues = roundTrip ? `${displayObDate} - ${displayIbDate}` : displayObDate;

  const displayMobileObDate = formatYearMonthDayPeriod(moment(obDate));
  const displayMobileIbDate = formatYearMonthDayPeriod(moment(ibDate));
  const displayMobileValues = roundTrip ? `${displayMobileObDate} - ${displayMobileIbDate}` : displayObDate;

  return (
    <div className={`grid-item-sm mb-3 ${cssClass}`}>
      {isMobile ?
        <div className="form-control form-control-lg rounded-0 input-icon input-icon-flip pr-6">
          <div className="text-truncate" onClick={toggleDatePicker}>
            {displayMobileValues}
          </div>
          <span onClick={toggleDatePicker} className="icon icon-calendar-2" aria-hidden="true"></span>
          <Modal
            show={showDatePicker}
            onHide={toggleDatePicker}
          >
            {dateOptions()}
          </Modal>
        </div>
        :
        <Dropdown
          id="datepicker-selection"
          onToggle={toggleDatePicker}
          open={showDatePicker}
          componentClass="div"
        >
          <OptionsToggle bsRole="toggle" faIcon="icon-calendar-2" label="calendar options">
            {displayValues}
          </OptionsToggle>
          <DropdownMenu className="datePickerDropdown pt-0 pb-3">
            {dateOptions()}
          </DropdownMenu>
        </Dropdown>
      }
    </div>
  );
};

DatePickerWrapper.defaultProps = {
  roundTrip: false  
};

DatePickerWrapper.propTypes = {
  isMobile: PropTypes.bool.isRequired,
  obDate: PropTypes.object.isRequired,
  ibDate: PropTypes.object.isRequired,
  setDate: PropTypes.func.isRequired,
  setDates: PropTypes.func.isRequired,
  roundTrip: PropTypes.bool.isRequired,
  setRoundTrip: PropTypes.func.isRequired,
  translations: PropTypes.object.isRequired,
  tripIndex: PropTypes.number,
  cssClass: PropTypes.string,
  disableBefore: PropTypes.object,
  disableAfter: PropTypes.object,
  hasRoundTripControl: PropTypes.bool
};

export default observer(DatePickerWrapper);
