import React, {Fragment} from 'react';
import {observer} from 'mobx-react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import LazyImage from 'helpers/LazyImage';
import margaretWhiteSVG from 'assets/images/svg/spinner/margaret-white.svg';

const SearchButton = ({multiTrip, product, loading, breakpoint, submitForm, translations}) => {
  const isBreakpointMd = breakpoint === 'md';
  const productIsFerry = product === 'ferry';

  const wrapperClasses = classNames({
    'grid-item': isBreakpointMd,
    'grid-item-lg-auto': !isBreakpointMd && !multiTrip,
    'grid-item-md': !isBreakpointMd && multiTrip,
    'mb-3': !isBreakpointMd,
    'px-2': multiTrip
  });

  return <div className={wrapperClasses}>
    <button
      aria-label={translations.search}
      type="button"
      className=
        {`btn-search btn btn-block btn-primary rounded-0 btn-${breakpoint} ${isBreakpointMd ? 'px-2' : 'px-4'}`}
      disabled={loading}
      onClick={(e) => submitForm(e)}
    >
      {
        loading ?
          <div className="smb-width">
            <LazyImage
              src={margaretWhiteSVG}
              title={translations.loading}
              alt={translations.loading}
              role="presentation"
            />
          </div> :
          <div className="smb-width">
            {
              productIsFerry  ?
                <span>{translations.search}</span> :
                <Fragment>
                  <i className={`icon icon-magnifier d-none d-${breakpoint}-inline`} aria-hidden="true"/>
                  <span className={`d-${breakpoint}-none`}>{translations.search}</span>
                </Fragment>
            }
          </div>
      }
    </button>
  </div>;
};

SearchButton.defaultProps = {
  breakpoint: 'lg',
  multiTrip: false
};

SearchButton.propTypes = {
  multiTrip: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
  product: PropTypes.string.isRequired,
  breakpoint: PropTypes.string.isRequired,
  submitForm: PropTypes.func.isRequired,
  translations: PropTypes.shape({
    search: PropTypes.string.isRequired,
    loading: PropTypes.string.isRequired
  })
};

export default observer(SearchButton);
