import _ from 'lodash';
import request from 'superagent';
import storage from 'utils/storage';

const queues = {};
const cache = storage.createStore({name: 'airportsCache'});
const fallbackAirports = storage.createStore({name: 'airportsFallback'});

function normalize(airports, term = '') {
  term = term.substr(0, 3).toUpperCase();
  const byCode = _.find(airports, {airportCode: term});
  const children = _.filter(airports, (airport) => airport.parentAirportCode);
  const parents = _.difference(airports, children);
  parents.forEach(function (parent) {
    parent.children = _.filter(children, {parentAirportCode: parent.airportCode});
  });
  const orphans = _.filter(
    children,
    (child) => !_.find(parents, {airportCode: child.parentAirportCode})
  );
  const parentsWithChildren = _.filter(parents, (parent) => parent.children.length);
  const parentsWithNoChildren = _.difference(parents, parentsWithChildren);

  if (parentsWithNoChildren.indexOf(byCode) !== -1) {
    parentsWithNoChildren.splice(parentsWithNoChildren.indexOf(byCode), 1);
  }
  if (byCode) {
    parentsWithNoChildren.unshift(byCode);
  }

  return {
    orphans,
    parentsWithChildren,
    parentsWithNoChildren
  };
}

// function group(airports, term) {
//   const {parentsWithChildren, parentsWithNoChildren, orphans} = normalize(airports, term);
//   return parentsWithChildren.concat(parentsWithNoChildren, orphans);
// }

function ungroupped(airports, term) {
  const {parentsWithChildren, parentsWithNoChildren, orphans} = normalize(airports, term);
  const expanded = _.flatten(parentsWithChildren.map((parent) => [parent, ...parent.children]));
  return expanded.concat(parentsWithNoChildren, orphans);
}

function createQueryPromise(name, q) {
  const queryRequest = request
    .get('/api/v1/flights/autocomplete/airports')
    .query({term: q})
    .timeout(1500);

  if (!queues[name]) {
    queues[name] = [];
  }
  queues[name].push(queryRequest);
  queues[name].splice(0, queues[name].length-1).forEach(function (request) {
    request.abort();
  });
  return queryRequest
    .then(function (results) {
      results = ungroupped(results.body, q);
      if (results.length > 0) {
        cache.setItem(q, results);
      }
      queues[name].splice(queues[name].indexOf(queryRequest), 1);
      return {
        name,
        results,
        term: q
      };
    })
    .catch(function () {
      return createFallbackQueryPromise(name, q);
    });
}

function query(name, q) {
  return cache
    .getItem(q)
    .then(function (results) {
      if (results) {
        return {
          name,
          results,
          term: q
        };
      }
      return createQueryPromise(name, q);
    });
}

function createAirportsProvider(name) {
  return _.partial(query, name);
}

function findSuggestionsOnStorage(q, storage) {
  const rx = new RegExp('^' + q, 'ig');
  const airportCodeRx = new RegExp(q, 'ig');
  const results = storage.filter(function (airport) {
    const cityMatches = airport.city.match(rx);
    const airportCodeMatches = airport.airportCode.match(airportCodeRx);
    const airportNameMatches = airport.airportName.match(rx);
    return cityMatches || airportCodeMatches || airportNameMatches;
  });
  return ungroupped(results, q);
}

function createFallbackQueryPromise(name, q) {
  return fallbackAirports
    .getItem('data')
    .then(function (storage) {
      return storage ||
        request
          .get('/api/v1/flights/autocomplete/fallback')
          .then(function ({body}) {
            fallbackAirports.setItem('data', body);
            return body;
          });
    })
    .then(function (storage) {
      return findSuggestionsOnStorage(q, storage);
    })
    .then(function (results) {
      return {
        name,
        results,
        term: q
      };
    });
}

export default {createAirportsProvider};
