import React from 'react';
import PropTypes from 'prop-types';
import {SelectField} from 'components/FormComponents';

const AirlinesSelection = ({airlinesGroup, selectAirline, value, translations}) => (
  <SelectField
    className='floating-label-show'
    selectName="airlinesGroup"
    htmlFor="airlinesGroup"
    optValue={translations.All}
    value={value}
    label={translations.airlineCode}
    onChange={(e) => selectAirline(e.target.value)}
    options={airlinesGroup}
  />
);

AirlinesSelection.propTypes = {
  airlinesGroup: PropTypes.array.isRequired,
  selectAirline: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
  translations: PropTypes.shape({
    airlineCode: PropTypes.string.isRequired,
    All: PropTypes.string.isRequired,
    openGroupAlliance: PropTypes.string.isRequired
  })
};

export default AirlinesSelection;
