import React from 'react';
import PropTypes from 'prop-types';
import {observer} from 'mobx-react';
import {ToggleSwitch} from 'components/FormComponents';

const SwitchElement = ({name, field, onChangeField}) => (
  <ToggleSwitch
    option={name}
    name={name}
    checked={field.isChecked}
    onChange={() => onChangeField(field, name)}
  />
);

SwitchElement.propTypes = {
  name: PropTypes.string.isRequired,
  field: PropTypes.shape({
    isChecked: PropTypes.bool.isRequired
  }).isRequired,
  onChangeField: PropTypes.func.isRequired
};

export default observer(SwitchElement);
