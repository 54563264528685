import React from 'react';
import PropTypes from 'prop-types';
import {observer} from 'mobx-react';

const CookieOption = ({item, toggleOption, translations}) => {
  const {active, inactive} = translations;
  const checked = item.value;

  return (
    <label className="list-group-item text-normal px-0 cursor-pointer">
      {toggleOption && (
        <div className="toggle-switch float-right">
          <input
            type="checkbox"
            checked={checked}
            value={item.id}
            name="gdpr-option"
            onChange={() => toggleOption(item)}
          />
          <span className="slider"/>
        </div>
      )}
      <h4>
        {item.name}
        {toggleOption && <span className="ml-1">({checked ? active : inactive})</span>}
      </h4>
      <div>{item.description}</div>
    </label>
  );
};

CookieOption.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    value: PropTypes.bool
  }).isRequired,
  toggleOption: PropTypes.func,
  translations: PropTypes.shape({
    active: PropTypes.string.isRequired,
    inactive: PropTypes.string.isRequired
  }).isRequired
};

export default observer(CookieOption);
