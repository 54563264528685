import moment from 'moment';
import {dateFormat} from 'helpers/stableValues';
import {
  formatMinTextDayUpperCase,
  formatDayDateMonth
} from './globalize-helper';

export default {
  getDate: function (date) {
    return date.getDate();
  },

  MinTextDayUpperCase: formatMinTextDayUpperCase,

  parseDate: function (strDate) {
    if (strDate instanceof Date) {
      return strDate;
    } else {
      const d = moment(strDate, [dateFormat.toUpperCase(), 'YYYY-MM-DD']);
      return d.isValid() ? d.toDate() : null;
    }
  },

  selectedFlexDate: function (object, index, options) {
    return parseInt((object.length / 2), 10) + 1 === parseInt(index + 1, 10) ?
      options.fn(this) : options.inverse(this);
  },

  stringifyDate: function (date) {
    date = (date instanceof Date) ? date : new Date(date);
    return this.formatDate(date, 'YYYY-MM-DD');
  },

  formatDate: function (date, format) {
    format = format || 'YYYY-MM-DDTHH:mm';
    const dateFormatted = moment(date).format(format);
    if (moment.localeData()['_abbr'] === 'ar-sa') {
      return moment.localeData().preparse(dateFormatted);
    }
    return dateFormatted;
  },

  formatDayDateMonth: formatDayDateMonth,
  // formatLocalizedDate: formatDate,

  isBefore: function (date, check) {
    check = check || new Date();
    return moment(date).isBefore(check);
  },

  isAfter: function (date, check) {
    check = check || new Date();
    return moment(date).isAfter(check);
  },

  dateToUTC: function (date) {
    return new Date(date.getUTCFullYear(), date.getUTCMonth(),
      date.getUTCDate(), date.getUTCHours(), date.getUTCMinutes(), date.getUTCSeconds());
  },

  daysBetween: function (date2, date1) {
    const one_day = 1000 * 60 * 60 * 24; // Get 1 day in milliseconds
    const difference_ms = date2 - date1; // Calculate the difference in milliseconds
    return Math.abs(Math.round(difference_ms / one_day)); // Convert back to days and return
  },

  getDateFromTimestamp: function (number) {
    if (number > 1893456000) {
      return this.dateToUTC(new Date(number));
    }
    return this.dateToUTC(new Date(number * 1000));
  }
};
