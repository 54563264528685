import React from 'react';
import PropTypes from 'prop-types';

const RoundtripRadios = ({roundTrip, setRoundTrip, onCloseClick, translations}) => (
  <div className="list-group-item text-center">
    <div className="radio d-inline-flex mr-3">
      <label>
        <input
          name='isRoundtrip'
          checked={roundTrip === true}
          type='radio'
          value='1'
          onChange={() => setRoundTrip(true)}
        /> <span className="d-inline-block"></span>{translations.roundtrip}
      </label>
    </div>
    <div className="radio d-inline-flex">
      <label>
        <input
          name='isRoundtrip'
          checked={roundTrip === false}
          type='radio'
          value='0'
          onChange={() => setRoundTrip(false)}
        /> <span className="d-inline-block mr-xs"></span>{translations.oneWay}
      </label>
    </div>
    <button type="button" className="close" onClick={onCloseClick}>
      <span aria-hidden="true">&times;</span>
      <span className="sr-only">close</span>
    </button>
  </div>
);

RoundtripRadios.propTypes = {
  roundTrip: PropTypes.bool.isRequired,
  setRoundTrip: PropTypes.func.isRequired,
  onCloseClick: PropTypes.func,
  translations: PropTypes.shape({
    roundtrip: PropTypes.string.isRequired,
    oneWay: PropTypes.string.isRequired
  })
};

export default RoundtripRadios;
