import React from 'react';
import PropTypes from 'prop-types';
import {observer} from 'mobx-react';
import classnames from 'classnames';

const Counter = ({value, step, min, max, onChange, circleDiameter}) => {
  const increment = () => onChange(Math.min(value + step, max));
  const decrement = () => onChange(Math.max(value - step, min));
  const btnMinusHTMLClasses = classnames(
    'btn rounded-circle p-0 btn-add-one', circleDiameter
  );
  const btnPlusHTMLClasses = classnames(
    'btn rounded-circle p-0 btn-add-one', circleDiameter
  );

  return (
    <div>
      <button
        className={btnMinusHTMLClasses}
        type='button'
        onClick={decrement}
        disabled={value <= min}><i className="icon icon-e-delete-1" aria-hidden="true"></i></button>
      <span className="px-2">{value}</span>
      <button
        className={btnPlusHTMLClasses}
        type='button'
        onClick={increment}
        disabled={value >= max}><i className="icon icon-e-add-1" aria-hidden="true"></i></button>
    </div>
  );
};

Counter.propTypes = {
  value: PropTypes.number.isRequired,
  min: PropTypes.number.isRequired,
  max: PropTypes.number.isRequired,
  step: PropTypes.number.isRequired,
  circleDiameter: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired
};

Counter.defaultProps = {
  step: 1,
  circleDiameter: 'w-5 h-5'
};

export default observer(Counter);
