import React, {useState, memo} from 'react';
import PropTypes from 'prop-types';
import LazyImage from 'helpers/LazyImage';
import {PropTypes as MobxPropTypes} from 'mobx-react';
import {FieldContainer, TextInput, Label, FieldErrors} from './components';

const getText = (reverse, required, focusOrValue, placeholder, label) =>
  `${(reverse && focusOrValue) ? label ?? placeholder : placeholder ?? label}${required ? '*' : ''}`;

const InputField = ({
  floatingLabel, inputName, value, label, onChange, onBlur, onClick, errors, htmlFor, placeholder, required,
  containerProps, inputProps, image, icon, disabled, readOnly, reverseLabel, showPlaceholderFocus,
  noFocus, labelProps, errorsContainerProps
}) => {
  const [focus, setFocus] = useState(false);
  const hasError = Boolean(errors.length);

  const onFocus = () => setFocus(true);
  const onBlurActions = (e) => {
    onBlur && onBlur(e);
    setFocus(false);
  };

  const focusOrValue = focus || !!value.length;

  const placeholderValue = getText(reverseLabel, required, focusOrValue, placeholder, label);
  const labelValue = getText(reverseLabel, required, focusOrValue, label, placeholder);

  return (
    <FieldContainer
      {...containerProps}
      value={value}
      floatingLabel={floatingLabel}
      hasError={hasError}
      showFloatingLabel={focusOrValue}
    >
      <TextInput
        {...inputProps}
        name={inputName}
        value={value}
        placeholder={focus ? (showPlaceholderFocus ? placeholderValue : '') : placeholderValue}
        hasError={hasError}
        disabled={disabled}
        readOnly={readOnly}
        onChange={onChange}
        onClick={onClick}
        onBlur={onBlurActions}
        onFocus={noFocus ? undefined : onFocus}
      />
      {
        image.src &&
        <div className="position-absolute" style={image.style} onClick={image.onClick}>
          <LazyImage
            wrapperClass="position-absolute"
            src={image.src}
            width={image.width}
            height={image.height}
            title=""
            alt=""
          />
        </div>
      }
      {
        icon.className &&
          <i
            style={icon?.style}
            className={`position-absolute icon ${icon.className}`}
            onClick={(!disabled && icon?.onClick) ? icon?.onClick : undefined}
            aria-hidden="true"
          />
      }
      <Label {...labelProps} htmlFor={htmlFor}>{labelValue}</Label>
      {hasError && <FieldErrors {...errorsContainerProps} errors={errors} />}
    </FieldContainer>
  );
};

InputField.propTypes = {
  floatingLabel: PropTypes.bool,
  inputName: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  disabled: PropTypes.bool.isRequired,
  required: PropTypes.bool.isRequired,
  readOnly: PropTypes.bool.isRequired,
  reverseLabel: PropTypes.bool.isRequired,
  showPlaceholderFocus: PropTypes.bool.isRequired,
  noFocus: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  onClick: PropTypes.func,
  onBlur: PropTypes.func,
  errors: MobxPropTypes.arrayOrObservableArrayOf(PropTypes.string).isRequired,
  htmlFor: PropTypes.string,
  placeholder: PropTypes.string,
  image: PropTypes.shape({
    src: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.object
    ]),
    width: PropTypes.number,
    height: PropTypes.number,
    style: PropTypes.object,
    onClick: PropTypes.func
  }),
  icon: PropTypes.shape({
    style: PropTypes.object,
    className: PropTypes.string,
    onClick: PropTypes.func
  }),
  containerProps: PropTypes.shape({
    id: PropTypes.string,
    className: PropTypes.string
  }),
  inputProps: PropTypes.shape({
    id: PropTypes.string,
    className: PropTypes.string
  }),
  labelProps: PropTypes.shape({
    id: PropTypes.string,
    className: PropTypes.string
  }),
  errorsContainerProps: PropTypes.shape({
    id: PropTypes.string,
    className: PropTypes.string
  })
};

InputField.defaultProps = {
  floatingLabel: true,
  inputName: '',
  value: '',
  label: '',
  errors: [],
  disabled: false,
  required: false,
  readOnly: false,
  reverseLabel: false,
  showPlaceholderFocus: false,
  noFocus: false,
  // Not required properties
  htmlFor: null,
  placeholder: null,
  containerProps: {},
  inputProps: {},
  labelProps: {},
  errorsContainerProps: {},
  image: {},
  icon: {}
};

export default memo(InputField);
