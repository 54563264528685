const FILTER_CARRIER_CODE = 'filter-carrier-code';
const filterCarrierCode = sessionStorage.getItem(FILTER_CARRIER_CODE);
const setFilterCarrierCode = (carrierCode) => sessionStorage.setItem(FILTER_CARRIER_CODE, carrierCode);
const removeFilterCarrierCode = () =>  sessionStorage.removeItem(FILTER_CARRIER_CODE);

const USER_LOGGEDIN_MESSAGE = 'user-loggedin-message';
const userLoggedinMessage = () => sessionStorage.getItem(USER_LOGGEDIN_MESSAGE);
const setUserLoggedinMessage = () => sessionStorage.setItem(USER_LOGGEDIN_MESSAGE, true);
const removeUserLoggedinMessage = () =>  sessionStorage.removeItem(USER_LOGGEDIN_MESSAGE);

export {
  filterCarrierCode,
  setFilterCarrierCode,
  removeFilterCarrierCode,
  userLoggedinMessage,
  setUserLoggedinMessage,
  removeUserLoggedinMessage
};