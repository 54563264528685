import React from 'react';
import ReactDOM from 'react-dom';
import CookieContainer from 'components/CookieConsent/CookieContainer';
import cookiePreferenceStore from 'components/CookiePreferences/CookiePreferencesStore';
import {isNative, isFerryscanner} from 'helpers/stableValues';
import {cookieConsentTranslations, gdprPreferencesTranslations} from 'helpers/translations';

if (!isNative && !isFerryscanner) {
  ReactDOM.render(
    React.createElement(CookieContainer, {
      cookiePreferenceStore, cookieConsentTranslations, gdprPreferencesTranslations
    }),
    document.getElementById('cookie-consent')
  );
}
