import {getRecentQueries} from 'providers/storage';
import airportsProvider from 'providers/airports';
import groupedAirlineOptions from 'helpers/formElements/selectOptions/groupedAirlineOptions';
import seatClassOptions from 'helpers/formElements/selectOptions/seatClassOptions';
import {validateQuery, fetchResultsUrl} from 'providers/searchResults';
import Store from './store';
import {searchMaskTranslations} from 'helpers/translations';
import {brand} from 'helpers/stableValues';

export default function createSearchMaskStore({showRecentQueries = false}) {
  return new Store({
    brand,
    airportsProvider,
    getRecentQueries,
    alliances: groupedAirlineOptions.alliances,
    airlines: groupedAirlineOptions.airlines,
    seatClasses: seatClassOptions,
    onSubmitForm: (url, accommodationUrl) => {
      if (accommodationUrl) {
        window.open(url, '_blank');
        window.location = accommodationUrl;
      } else {
        window.location = url;
      }
    },
    validateForm: validateQuery,
    fetchResultsUrl,
    showRecentQueries,
    translations: searchMaskTranslations
  });
}
